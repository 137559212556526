import axios from 'axios';
import config from '@/lib/config';
import { getToken } from "@/lib/auth";

class Requests {
    constructor() {
        this.api = config.getApi();
        this.AI = axios.create({
            baseURL: this.api
        });

        this.AI.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 405) {
                    //console.warn('Method Not Allowed (405):', error.response);
                }
                //return Promise.reject(error);
                return error.response
            }
        );
    }

    async post(path, data = null, token = false) {
        const headers = {
            'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
        };
        if (token) {
            headers['Authorization'] = "Bearer " + getToken();
        }
        return await this.AI.post(path, data, { headers })
    }

    async get(path, params = {}, token = false) {
        const headers = {};
        if (token) {
            headers['Authorization'] = "Bearer " + token;
        }
        try {
            const response = await this.AI.get(path, { headers, params });
            return response;
        } catch (error) {
            console.error('Error in GET request:', error);
            throw error;
        }
    }

    async delete(path, token = false) {
        const headers = {};
        if (token) {
            headers['Authorization'] = "Bearer " + token;
        }
        try {
            const response = await this.AI.delete(path, { headers });
            return response;
        } catch (error) {
            console.error('Error in DELETE request:', error);
            throw error;
        }
    }
}

export default new Requests();
