<template>
  <div class="container profile-container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-10">
        <div class="profile-card">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <button @click="goBack" class="btn btn-secondary">Back</button>
            <h2 class="text-center flex-grow-1 mb-0">User Profile</h2>
          </div>
          <div class="profile-info" v-if="user">
            <p><strong>User ID:</strong> {{ user.user_id }}</p>
            <p><strong>Email:</strong> {{ user.email }}</p>
            <p><strong>Username:</strong> {{ user.username }}</p>
            <p><strong>Created At:</strong> {{ formattedCreatedAt }}</p>
          </div>
          <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
            {{ errorMessage }}
          </div>
        </div>
        <AudioProfileTable :actions="positions" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getToken } from "@/lib/auth";
import requests from '@/lib/requests';
import AudioProfileTable from '@/components/AudioProfile.vue';

export default {
  components: {
    AudioProfileTable
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const user = ref(null);
    const errorMessage = ref('');
    const positions = ref([]);

    const fetchUserData = async (id) => {
      try {
        const response = await requests.get(`/user/${id}`, {}, getToken());
        if (response && response.data) {
          user.value = response.data;
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        errorMessage.value = error.response && error.response.data.detail
          ? error.response.data.detail
          : 'An error occurred while fetching the user data.';
      }
    };

    const fetchPositionsData = async (id) => {
      try {
        const response = await requests.get(`exercise/pad/view/${id}`, {}, getToken());
        if (response && response.data && response.data.positions) {
          positions.value = response.data.positions;
        } else {
          throw new Error('Failed to fetch positions data');
        }
      } catch (error) {
        errorMessage.value = error.response && error.response.data.detail
          ? error.response.data.detail
          : 'Incomplete PAD';
        console.error('Error fetching positions data:', errorMessage.value); // Debug log
      }
    };

    const goBack = () => { 
      router.back();
    };

    onMounted(() => {
      const userId = route.params.id;
      fetchUserData(userId);
      fetchPositionsData(userId);
    });

    const formattedCreatedAt = computed(() => {
      return user.value ? new Date(user.value.created_at).toLocaleString() : '';
    });

    return {
      user,
      errorMessage,
      formattedCreatedAt,
      goBack,
      positions
    };
  }
};
</script>

<style scoped>
.profile-container {
  margin-top: 3vh;
}

.profile-card {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-info p {
  margin: 10px 0;
}

h2 {
  margin-bottom: 20px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 15px;
  border-radius: 5px;
}
</style>
