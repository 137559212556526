<template>
    <div class="container-fluid invite-container">
        <div class="row justify-content-center mx-auto">
            <div class="invite-form position-relative">
                <h2 class="text-center">Invites</h2>
                <form @submit.prevent="sendInvite">
                    <div class="mb-3">
                        <label for="emailOrPhone" class="form-label">Email</label>
                        <input type="text" class="form-control" id="emailOrPhone" v-model="emailOrPhone" required />
                    </div>

                    <div v-if="isAdmin" class="mb-3">
                        <label for="userType" class="form-label">User Type</label>
                        <div>
                            <input type="radio" id="normalUser" value="normal" v-model="userType" />
                            <label for="normalUser" class="ps-2">Normal User</label>
                        </div>
                        <div>
                            <input type="radio" id="portalUser" value="portal" v-model="userType" />
                            <label for="portalUser" class="ps-2">Portal User</label>
                        </div>
                    </div>

                    <div v-if="!isAdmin || userType === 'normal'">
                        <div class="mb-3">
                            <label for="deviceBrand" class="form-label">Device brand</label>
                            <select class="form-control" id="deviceBrand" v-model="selectedBrand"
                                @change="onBrandChange">
                                <option value="" disabled>Please select a brand</option>
                                <option v-for="brand in brands" :key="brand.brand_id" :value="brand.brand_id">{{
                                    brand.name }}</option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="selectedBrandModels.length">
                            <label for="deviceModel" class="form-label">Model</label>
                            <select class="form-control" id="deviceModel" v-model="selectedModel">
                                <option value="" disabled>Please select a model</option>
                                <option v-for="model in selectedBrandModels" :key="model.device_model_id"
                                    :value="model.device_model_id">{{ model.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="isAdmin && userType === 'portal'" class="mb-3">
                        <label for="group" class="form-label">Group</label>
                        <select class="form-control" id="group" v-model="selectedGroup">
                            <option value="" disabled>Please select a group</option>
                            <option v-for="group in filteredGroups" :key="group.group_id" :value="group.group_id">{{
                                group.group_name }}</option>
                        </select>
                    </div>

                    <div class="text-center">
                        <button type="submit" class="btn btn-primary">Send Invite</button>
                    </div>
                </form>
                <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";

export default {
    data() {
        return {
            brands: [],
            groups: [],
            emailOrPhone: '',
            selectedBrand: '',
            selectedModel: '',
            selectedGroup: '',
            userType: 'normal',
            isAdmin: false,
            errorMessage: ''
        };
    },
    computed: {
        selectedBrandModels() {
            const selectedBrand = this.brands.find(brand => brand.brand_id === this.selectedBrand);
            return selectedBrand ? selectedBrand.models : [];
        },
        filteredGroups() {
            return this.groups.filter(group => group.group_name !== 'admin' && group.group_name !== 'portal-user');
        }
    },
    async created() {
        await this.isUserAdmin();
        if (this.isAdmin) {
            this.getGroups();
        }
        this.getBrands();
    },
    methods: {
        async getGroups() {
            const response = await requests.get("permissions/group", {}, getToken());
            this.groups = response.data;
        },
        async isUserAdmin() {
            const response = await requests.get("permissions/user/group", {}, getToken());
            const permissionsGroups = response.data;
            this.isAdmin = permissionsGroups.some(group => group.group_name === 'admin');
        },
        async getBrands() {
            try {
                const response = await requests.get("device/brands", {}, getToken());
                this.brands = response.data;
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        },
        async sendInvite() {
            try {
                let payload = {
                    username: this.emailOrPhone,
                };
                
                if (this.userType == 'normal') {
                    if (this.selectedBrand && this.selectedModel) {
                        payload.brand = this.selectedBrand;
                        payload.model = this.selectedModel;
                    } else {
                        this.errorMessage = 'Please select brand, model';
                        return;
                    }
                } else {
                    if (this.selectedGroup) {
                        payload.group = this.selectedGroup
                    }
                }

                if (this.isAdmin) {
                    payload.usertype = this.userType;
                }

                console.log(payload)

                const response = await requests.post("/transactional/invite", payload, getToken());
                if (response && response.status === 201) {
                    this.$router.push('/users/dashboard');
                } else if (response && response.data && response.data.detail) {
                    this.errorMessage = 'An unknown error occurred.';
                } else {
                    this.errorMessage = 'An unknown error occurred.';
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.detail) {
                    this.errorMessage = error.response.data.detail;
                } else {
                    this.errorMessage = 'An error occurred while sending the invite.';
                }
            }
        },
        onBrandChange() {
            this.selectedModel = '';  // Reset the selected model when the brand changes
        }
    }
};
</script>

<style scoped>
.invite-container {
    margin: auto;
    margin-top: 3vh;
}

.invite-form {
    background-color: #d5dadb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
}

.alert {
    margin-top: 10px;
}
</style>