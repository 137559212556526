<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Users</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Navigation bar -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <!-- Navigation items -->
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="inviteUser">
                                        Invite user
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="white-box">
                    <p>
                        <label for="objectName" class="form-label">Username :</label>
                        <span>
                            <input type="text" class="form-control iblock" id="objectName" v-model="filter">
                            <button class="btn btn-primary ibutton" type="button" @click="onSearch">Search</button>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="white-box">
                    <p>
                        Total users: {{ total_users }}
                    </p>
                    <!-- Pagination with square borders -->
                    <div v-if="totalPages > 0" class="pagination-container">
                        <button 
                            class="btn page-btn" 
                            :class="{ active: currentPage === 0 }" 
                            @click="onPageSelected(0)"
                        >
                            1
                        </button>

                        <button
                            v-for="page in displayedPages"
                            :key="page"
                            class="btn page-btn"
                            :class="{ active: page === currentPage }"
                            @click="onPageSelected(page)"
                        >
                            {{ page + 1 }}
                        </button>

                        <button 
                            class="btn page-btn" 
                            :class="{ active: currentPage === totalPages - 1 }" 
                            @click="onPageSelected(totalPages - 1)"
                        >
                            {{ totalPages }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <ul class="list-group">
                    <li v-for="(user, index) in users" :key="index"
                        class="mb-1 list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            {{ user.username }}
                            <font-awesome-icon v-if="user.has_pad" icon="file-alt" class="ms-2 text-pad-icon"/>
                        </span>
                        <button class="btn btn" @click="goToUserProfile(user.user_id)">
                            <font-awesome-icon icon="chart-bar" /> View profile
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import requests from '@/lib/requests';
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChartBar, faFileAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faChartBar, faFileAlt);

export default {
    name: 'ObjectsDashboard',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            users: [],
            total_users: 0,
            filter: "",
            currentPage: 0 // Start from 0 to match offset
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total_users / 25);
        },
        displayedPages() {
            const pages = [];
            const maxDisplayedPages = 5; // Max number of pages to show between first and last

            let startPage = Math.max(1, this.currentPage - Math.floor(maxDisplayedPages / 2));
            let endPage = Math.min(this.totalPages - 2, this.currentPage + Math.floor(maxDisplayedPages / 2));

            if (endPage - startPage < maxDisplayedPages - 1) {
                startPage = Math.max(1, endPage - maxDisplayedPages + 1);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    created() {
        this.filter = this.$route.query.filter || "";
        this.currentPage = parseInt(this.$route.query.page, 10) || 0; // Start from page 0
        this.fetchObjects();
    },
    watch: {
        '$route.query': {
            handler(newQuery) {
                this.filter = newQuery.filter || "";
                this.currentPage = parseInt(newQuery.page, 10) || 0;
                this.fetchObjects();
            },
            immediate: true
        }
    },
    methods: {
        goToUserProfile(id) {
            this.$router.push('/users/profile/' + id);
        },
        async fetchObjects() {
            const offset = this.currentPage;
            const resp = await requests.get("user/admin-list", {
                offset,
                filter: this.filter
            }, getToken());
            this.users = resp.data.user_list;
            this.total_users = resp.data.total_users;
            console.log("Users Loaded: ", this.users.length);
            console.log("Total Users: ", this.total_users);
            console.log("Current Page: ", this.currentPage);
            console.log("Total Pages: ", this.totalPages);
        },
        onSearch() {
            this.$router.push({
                path: this.$route.path,
                query: { 
                    filter: this.filter, 
                    page: 0
                }
            });
        },
        onPageSelected(page) {
            console.log("Navigating to Page: ", page);
            this.$router.push({
                path: this.$route.path,
                query: { 
                    filter: this.filter, 
                    page 
                }
            });
        },
        inviteUser() {
            this.$router.push('/users/invite');
        }
    }
};
</script>

<style scoped>
.iblock {
    display: inline-block; 
    width: auto;
    margin-left: 10px;
}

.ibutton {
    display: inline-block;
    margin-left: 10px;
}

h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}

.alert-info p {
    margin-bottom: 0;
}

.white-box {
    color: #383d41;
    background-color: #ffffff;
    border-color: #d6d8db;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
}

.text-pad-icon {
    color: #6c757d;
}

.ms-2 {
    margin-left: 0.5rem;
}

.pagination-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}

.page-btn {
    border: 1px solid #ced4da;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.page-btn:hover {
    background-color: #f0f0f0;
}

.page-btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}
</style>
