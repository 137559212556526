import { createRouter, createWebHistory } from 'vue-router';
import { getToken, isTokenExpired, removeToken } from '@/lib/auth';
import HomePage from './views/HomePage.vue';
import AboutPage from './views/AboutPage.vue';
import LoginPage from './views/LoginPage.vue';
import ResetPage from './views/ResetPage.vue';
import ResetActionPage from './views/ResetActionPage.vue';
import ConfirmResetPage from './views/ConfirmResetPage.vue';
import PortalUserProfile from './views/profile/ProfilePage.vue';
import AdminDashboard from './views/admin/AdminDashboard.vue';
import ObjectsDashboard from './views/admin/objects/ObjectDashbaord.vue';
import CreateObject from './views/admin/objects/CreateObject.vue';
import PermissionPage from './views/admin/permissions/PermissionPage.vue';
import GroupsDashboard from './views/admin/groups/GroupsDashboard.vue';
import CreateGroup from './views/admin/groups/CreateGroup.vue';
import UserDashboard from './views/users/UserDashboard.vue'
import UserInvite from './views/users/InvitePage.vue';
import UserProfilePage from './views/users/UserProfilePage.vue';
import DeviceDashboard from './views/admin/Devices/DeviceDashboard.vue'
import CreateBrand from './views/admin/Devices/CreateBrand.vue'
import CreateModel from './views/admin/Devices/CreateModel.vue'
import BrandPage from './views/admin/Devices/BrandPage.vue'
import AdminUserDashbarod from './views/admin/users/UsersDashboard.vue';

const routes = [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/about', component: AboutPage },
    { path: '/reset-password', component: ResetPage },
    { path: '/confirm-change-password', component: ConfirmResetPage },
    { path: '/change-password', component: ResetActionPage },
    { path: '/profile', component: PortalUserProfile },
    { path: '/users/dashboard', component: UserDashboard },
    { path: '/users/profile/:id', component: UserProfilePage },
    { path: '/users/invite', component: UserInvite },
    { path: '/admin/dashboard', component: AdminDashboard },
    { path: '/admin/objects', component: ObjectsDashboard },
    { path: '/admin/objects/create', component: CreateObject },
    { path: '/admin/permissions/:id', component: PermissionPage },
    { path: '/admin/groups', component: GroupsDashboard },
    { path: '/admin/group/create', component: CreateGroup},
    { path: '/admin/device/dashboard', component: DeviceDashboard},
    { path: '/admin/brand/create', component: CreateBrand},
    { path: '/admin/brand/:id', component: BrandPage},
    { path: '/admin/model/create/:id', component: CreateModel},
    { path: '/admin/users', component: AdminUserDashbarod},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// These are paths that are to be excluded from the auth middleware
const excludedPaths = [
    '/login',
    '/reset-password',
    '/update-password',
    '/confirm-change-password',
    '/change-password'
];


router.beforeEach((to, from, next) => {
    const token = getToken();
    const currentPath = to.path.split('?')[0];
    
    if (!excludedPaths.includes(currentPath) && (!token || isTokenExpired(token))) {
        removeToken();
        next('/login');
    } else {
        next();
    }
});

export default router;
