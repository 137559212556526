class Config {
    constructor() {
        this.loadEnvVariables();
    }

    loadEnvVariables() {
        this.nodeEnv = process.env.VUE_APP_NODE_ENV || 'development';
        this.api = process.env.VUE_APP_API || 'http://localhost:8000/';
    }

    getPort() {
        return this.port;
    }

    getHost() {
        return this.host;
    }

    getNodeEnv() {
        return this.nodeEnv;
    }

    getApi() {
        return this.api;
    }
}

export default new Config();