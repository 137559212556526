<template>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4">
          <div v-if="userData">
            <h2 class="text-center mb-4">Profile</h2>
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input type="text" class="form-control" id="username" v-model="userData.username" disabled>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" id="email" v-model="userData.email">
            </div>
            <div class="text-center">
              <button class="btn btn-primary" @click="updateProfile">Save Changes</button>
            </div>
            <div class="mt-3">
              <h3 class="text-center">Change Password</h3>
              <div class="mb-3">
                <label for="oldPassword" class="form-label">Old Password</label>
                <input type="password" class="form-control" id="oldPassword" v-model="oldPassword">
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label">New Password</label>
                <input type="password" class="form-control" id="newPassword" v-model="newPassword">
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label">Confirm New Password</label>
                <input type="password" class="form-control" id="confirmPassword" v-model="confirmPassword">
              </div>
              <div class="text-center">
                <button class="btn btn-primary" @click="changePassword">Change Password</button>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { getToken } from "@/lib/auth"
  
  export default {
    data() {
      return {
        userData: null,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      };
    },
    async mounted() {
      await this.fetchUserData();
    },
    methods: {
      async fetchUserData() {
        try {
          const token = getToken(); // Assuming you have a function to get the token
          const response = await axios.get('https://staging-api.meludia.com/user', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.userData = response.data;
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      },
      async updateProfile() {
        try {
          // Logic to update profile data
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      },
      async changePassword() {
        try {
          // Logic to change password
        } catch (error) {
          console.error('Error changing password:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  </style>
  