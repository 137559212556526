<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Admin Dashboard</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminUsers">Users</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminDevices">Devices</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminGroups">Groups</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminObjects">Objects</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getToken } from "@/lib/auth";

export default {
    name: 'AdminDashboard',
    created() {
        this.checkAdminGroup();
    },
    methods: {
        async checkAdminGroup() {
            const isAdmin = await this.isUserAdmin();

            if (!isAdmin) {
                this.$router.push('/login');
            }
        },
        async isUserAdmin() {
            const token = getToken();
            const response = await axios.get('https://staging-api.meludia.com/permissions/user/group', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const permissionsGroups = response.data;
            return permissionsGroups.some(group => group.group_name === 'admin');
        },
        goToAdminGroups() {
            this.$router.push('/admin/groups');
        },
        goToAdminObjects() {
            this.$router.push('/admin/objects');
        },
        goToAdminUsers() {
            this.$router.push('/admin/users');
        },
        goToAdminDevices() {
            this.$router.push('/admin/device/dashboard');
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}
</style>