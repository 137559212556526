<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Objects Dashboard</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Navigation bar -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <!-- Navigation items -->
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminDashboard">
                                        <font-awesome-icon icon="arrow-left" class="me-2" /> Back
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminObjectsCreate">
                                        Create
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <!-- Notice area -->
        <div class="row mt-4">
            <div class="col">
                <div class="alert alert-info text-center" role="alert">
                    <p>
                        These are objects that are meant for the developers.
                        They are used to add permissions in the API.<br />
                        Do not add or remove objects unless you know what you are doing.
                    </p>
                </div>
            </div>
        </div>
        <!-- Object list -->
        <div class="row mt-4">
            <div class="col">
                <ul class="list-group">
                    <li v-for="(object, index) in objects" :key="index"
                        class="mb-1 list-group-item d-flex justify-content-between align-items-center">
                        {{ object.name }}
                        <button class="btn btn-danger" @click="confirmDeleteObject(object.object_id)">
                            <font-awesome-icon icon="xmark" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft, faXmark);

export default {
    name: 'ObjectsDashboard',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            objects: []
        };
    },
    created() {
        this.fetchObjects();
    },
    methods: {
        async fetchObjects() {
            try {
                const token = getToken();
                const response = await axios.get('https://staging-api.meludia.com/objects', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                this.objects = response.data;
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        },
        goToAdminDashboard() {
            this.$router.push('/admin/dashboard');
        },
        goToAdminObjectsCreate() {
            this.$router.push('/admin/objects/create');
        },
        confirmDeleteObject(objectId) {
            if (window.confirm('Are you sure you want to delete this object?')) {
                this.deleteObject(objectId);
            }
        },
        async deleteObject(objectId) {
            try {
                const token = getToken();
                await axios.delete(`https://staging-api.meludia.com/objects/${objectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                this.fetchObjects();
            } catch (error) {
                console.error('Error deleting object:', error);
            }
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}

.alert-info p {
    margin-bottom: 0;
}
</style>