<template>
    <div class="container-fluid">
        <SidebarNavigation v-if="shouldShowSidebar" />
        <div class="content">
            <router-view />
        </div>
    </div>
</template>

<script>
import SidebarNavigation from '@/components/SidebarNavigation.vue';
import router from '@/routes';

export default {
    components: {
        SidebarNavigation
    },
    data() {
        return {
            excludedPaths: [
                '/login',
                '/reset-password',
                '/update-password',
                '/confirm-change-password',
                '/change-password'
            ]
        };
    },
    computed: {
        shouldShowSidebar() {
            const currentPath = router.currentRoute.value.path.split('?')[0];
            return !this.excludedPaths.includes(currentPath);
        }
    },
    created() {
        setInterval(this.checkTokenExpiration, 5 * 60 * 1000);
    },
    methods: {
        checkTokenExpiration() {
            const currentPath = router.currentRoute.value.path.split('?')[0];
            if (this.excludedPaths.includes(currentPath)) {
                return;
            }

            const tokenExpiration = localStorage.getItem('tokenExpiration');
            const currentTime = new Date().getTime();

            if (tokenExpiration && parseInt(tokenExpiration) < currentTime) {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                router.push('/login');
            }
        }
    }
};
</script>

<style scoped>
.container-fluid {
    display: flex;
    margin: 0px !important;
    padding: 0px !important;
}

.content {
    flex: 1;
    padding: 20px;
    overflow-x: hidden;
    height: 100%;
}
</style>
