<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">My users</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Navigation bar -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <!-- Navigation items -->
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="inviteUser">
                                        Invite user
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <ul class="list-group">
                    <li v-for="(user, index) in users" :key="index"
                        class="mb-1 list-group-item d-flex justify-content-between align-items-center">
                        {{ user.user.username }}
                        <button class="btn btn" @click="goToUserProfile(user.invite_user_id)">
                            <font-awesome-icon icon="chart-bar" /> View profile
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import requests from '@/lib/requests';
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';

library.add(faChartBar);

export default {
    name: 'ObjectsDashboard',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            users: [],
            pages: 0
        };
    },
    created() {
        this.fetchObjects();
    },
    methods: {
        goToUserProfile(id) {
            this.$router.push('/users/profile/' + id);
        },
        getFilters() {
            return ""
        },
        async fetchObjects() {
            const resp = await requests.get("user/list", {
                "offset": 0,
                "filter": "None" 
            }, getToken());
            this.users = resp.data.user_list;
            this.pages = resp.data.pages;
            console.log(resp.data);
        },
        inviteUser() {
            this.$router.push('/users/invite');
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}

.alert-info p {
    margin-bottom: 0;
}
</style>